import React from 'react';
import PropTypes from 'prop-types';
import Icons from 'bootstrap-italia/dist/svg/sprites.svg';
import classNames from 'classnames';

// Component for icon boostrap italia

const BIIcon = ({ name, size = 'xs', color, className }) => (
  <svg
    className={classNames(
      'icon',
      'flex-shrink-0',
      name && `icon-${name}`,
      size && `icon-${size}`,
      color && `icon-${color}`,
      className
    )}
  >
    <use xlinkHref={`${Icons}#it-${name}`} />
  </svg>
);

BIIcon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string
};

export default BIIcon;
