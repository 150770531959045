import React from 'react';

// css splide - carousel
import '@splidejs/react-splide/css';

import 'bootstrap-italia/dist/bootstrap-italia.esm';
import 'gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css';

import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';

import '@styles/global.scss';

import Layout from '@components/Layout';

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  return location?.state?.updateScroll ?? true;
};
