import React from 'react';
import Header from '@components/Header';
import Footer from '@components/Footer';

import { graphql } from 'gatsby';
const Layout = ({ children, data }) => {
  return (
    <>
      <Header data={data} />
      {children}
      <Footer />
    </>
  );
};

export const query = graphql`
  fragment TagFragmentTag on taxonomy_term__dataset_tag {
    name
    path {
      alias
      langcode
    }
  }

  fragment TagFragmentArgomenti on taxonomy_term__dataset_argomenti {
    id
    name
  }

  {
    allNodePageOpendata {
      nodes {
        path {
          alias
          langcode
        }
      }
    }
  }
`;
export default Layout;
