import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
const Footer = () => {
  return (
    <footer className=''>
      <div className='it-footer-main bottom py-3  primary-bg-a9'>
        <div className='container '>
          <section className='py-3 row d-flex justify-content-between g-lg-0 '>
            <div className='row gap-1 gap-md-3 gap-lg-5'>
              <div className='col-12 col-md-3 d-flex align-items-center ps-lg-0 gap-2 gap-md-4 border-bottom border-md-right border-md-bottom-0 pb-4 pb-md-0 py-md-1'>
                <Link to='https://commission.europa.eu/'>
                  <StaticImage
                    src='../images/icons/bandiera_europa.svg'
                    alt='logo-ministero'
                  />
                </Link>
              </div>
              <div className='col-12 col-md-3 d-flex align-items-center ps-lg-0 gap-4 border-bottom border-md-right border-md-bottom-0 py-4 py-md-1'>
                <Link
                  to='https://www.salute.gov.it/'
                  className=' text-white text-decoration-none d-flex align-items-center gap-3'
                >
                  <StaticImage
                    src='../images/icons/logo_ministero.svg'
                    alt='logo-ministero'
                  />
                  <span>Ministero Della Salute</span>
                </Link>
              </div>
              <div className='col-12 col-md-3 d-flex align-items-center ps-lg-0 justify-content-md-center gap-4 py-4 py-md-1'>
                <Link to='https://www.italiadomani.gov.it/'>
                  <StaticImage
                    src='../images/icons/logo_italia_domani.svg'
                    alt='logo-ministero'
                  />
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className='bg-primary text-white py-5'>
        <div className='container'>
          <div className='row'>
            <div className='d-flex align-items-center col-12 col-md-8 flex-wrap'>
              <div className='d-flex gap-2 align-items-center mb-4'>
                <StaticImage
                  className='logo-repubblica'
                  src='../images/icons/Stemma_Repubblica_Italiana.svg'
                  alt='logo-repubblica'
                />
                <div>
                  © 2024 - Testata di proprietà del Ministero della Salute
                </div>
              </div>

              <div className='fw-light mb-5 mb-md-0'>
                a cura di{' '}
                <a
                  href='https://www.salute.gov.it/portale/ministro/p4_5_2_4_1.jsp?lingua=italiano&menu=uffCentrali&label=uffCentrali&id=1158'
                  className='fw-semibold text-white ms-2'
                >
                  Direzione generale della digitalizzazione, del sistema
                  informativo sanitario e della statistica
                </a>
              </div>
            </div>
            <div className='col-12 col-md-4 fw-light '>
              Tranne per i materiali specificamente ed espressamente indicati
              come diversamente tutelati, i contenuti del sito sono soggetti
              alla licenza IODL{' '}
              <a
                className=' ms-2 text-white fw-semibold'
                href='http://www.dati.gov.it/iodl/2.0/'
              >
                "Italian Open Data License"
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
