import React from 'react';
import BIIcon from '@components/BIIcon';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useLocation } from '@gatsbyjs/reach-router';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';

const Header = ({ data }) => {
  const location = useLocation();
  const noteRawPath = data?.allNodePageOpendata?.nodes.find((el) =>
    el.path.alias.includes('note')
  );

  const notePath =
    noteRawPath?.path?.alias && noteRawPath?.path?.langcode
      ? `/${noteRawPath?.path?.langcode}${noteRawPath?.path?.alias}`
      : '#';

  const cosaSonoRawPath = data?.allNodePageOpendata?.nodes.find((el) =>
    el.path.alias.includes('cosa-sono')
  );

  const cosaSonoPath =
    cosaSonoRawPath?.path?.alias && cosaSonoRawPath?.path?.langcode
      ? `/${cosaSonoRawPath?.path?.langcode}${cosaSonoRawPath?.path?.alias}`
      : '#';

  const menuLink = [
    {
      page: 'home',
      text: 'Home',
      url: '/'
    },
    {
      page: 'cosa-sono',
      text: ' Cosa Sono',
      url: cosaSonoPath
    },

    {
      page: 'note',
      text: 'Note',
      url: notePath
    }
  ];

  return (
    <header>
      <div className='primary-bg-a9 '>
        <div className='container py-3 d-flex align-items-center justify-content-start'>
          <Link
            to='https://www.salute.gov.it/'
            className=' text-white text-decoration-none d-flex align-items-center gap-3'
          >
            <StaticImage
              className='logo-repubblica'
              src='../images/icons/Stemma_Repubblica_Italiana.svg'
              alt='logo-repubblica'
            />

            <div className=''>Ministero della Salute</div>
          </Link>
        </div>
        <div className='pt-4 bg-primary d-flex flex-column'>
          <div>
            <div className='container d-flex justify-content-between align-items-center'>
              <Link to='/' className='text-decoration-none'>
                <h3 className='text-white'>Open data </h3>
              </Link>
              <a
                className='text-white fw-semibold'
                target='_blank'
                href={`/rss.xml`}
              >
                <span className='me-2'>Resta Aggiornato</span>
                <BIIcon name='rss' />
              </a>
            </div>
          </div>

          <div className=' container d-flex gap-5  mt-4'>
            {menuLink.map((el) => {
              return (
                <Link
                  key={`menuLink-${uuidv4()}`}
                  to={el.url}
                  className={`border-bottom border-primary border-2 text-decoration-none py-1 ${
                    location.pathname == '/' && el.page === 'home'
                      ? 'border-white border-2'
                      : ''
                  } ${
                    location.pathname !== '/' &&
                    location.pathname.includes(el.page) &&
                    'border-white border-2'
                  } `}
                >
                  <span className='text-white  px-3'>{el.text}</span>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </header>
  );
};

export const query = graphql`
  fragment TagFragmentTag on taxonomy_term__dataset_tag {
    name
    path {
      alias
      langcode
    }
  }

  fragment TagFragmentArgomenti on taxonomy_term__dataset_argomenti {
    id
    name
  }

  {
    allNodePageOpendata {
      nodes {
        path {
          alias
          langcode
        }
      }
    }
  }
`;

export default Header;
